/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox, ColorPicker, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import 'react-quill/dist/quill.snow.css';
import { ExportIcon, TrashIcon } from 'assets/icon/dynamic';
import {
  fetchInformationCategories,
  modifyInformationCategory,
  createInformationCategory,
  setInformationCategory,
} from 'state/actions/informationCategories';
import { setInformation } from 'state/actions/information';
import _ from 'underscore';
import './InformationForm.css';

import CategoryDropdown from 'components/CategoryDropdown';
import ModalInformationCategoryForm from 'components/ModalInformationCategoryForm';
import ReactQuill from 'react-quill';

const InformationForm = ({
  //   poi,
  action,
  history,
  id,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData, categoriesList, information, category } =
    useSelector(
      (state) => ({
        loading: state.pois.loading,
        userData: state.auth.userData,
        categoriesList: state.informationCategories.list,
        information: state.information.information,
        category: state.informationCategories.category,
      }),
      shallowEqual
    );

  const uid = userData.id;

  const dispatch = useDispatch();
  const [tempSelectedCategory, setTempSelectedCategory] = useState();
  const [modalCategory, setModalCategory] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);

  useEffect(() => {
    if (information) {
      setTempSelectedCategory(information.categoryId);
    }
  }, [information]); // eslint-disable-line

  useEffect(() => {
    // fetch floor and poi categories
    // dispatch(fetchFloor({ siteId }));
    dispatch(fetchInformationCategories({ siteId }));
  }, [siteId]); // eslint-disable-line

  // const onChangeHandler = useChangeHandler(setPoi);

  const onFileChangedHandler = (_, fileList) => {
    const files = [...information.files, ...fileList];
    dispatch(
      setInformation({
        ...information,
        files,
      })
    );
    return false;
  };

  const handleRemoveFile = (index) => {
    const findFiles = information.files[index];
    const deletedFiles = information.deletedFiles || [];

    if (findFiles.logo) {
      deletedFiles.push(findFiles);
    }

    const files = [...information.files];
    files.splice(index, 1);

    dispatch(
      setInformation({
        ...information,
        files,
        deletedFiles,
      })
    );
  };

  const onSubmitCategory = () => {
    const action = category._id
      ? modifyInformationCategory
      : createInformationCategory;

    dispatch(
      action({ uid, ...category, siteId, id: category._id }, () =>
        setModalCategory(false)
      )
    );
  };

  const handleChange = (name, value) => {
    dispatch(
      setInformation({
        ...information,
        [name]: value,
      })
    );
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (!regexInput(information.name) && information.name) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(information.name) &&
        information.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = information.description && [
        { msg: 'This field cannot contain any special characters' },
      ];

      setErrors(newErrors);
    } else {
      dispatch(
        action(
          {
            uid,
            ...information,
            siteId,
            id,
          },
          () => history.push(`/${siteId}/information/${siteName}`)
        )
      );
    }
  };

  const onSelectCategoryHandler = () => {
    setPlaylistOpen(false);
    dispatch(
      setInformation({
        ...information,
        categoryId: tempSelectedCategory,
        categoryName: categoriesList.find(
          (cat) => cat._id === tempSelectedCategory
        )?.name,
        // categoryName,
      })
    );
  };

  const handleNew = () => {
    dispatch(
      setInformationCategory(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalCategory(true)
      )
    );
  };
  const handleSetCategory = (data) => {
    dispatch(setInformationCategory(data));
  };

  const getCategory = (categoryId) => {
    const findCategory = categoriesList.find((cat) => cat._id === categoryId);
    return findCategory?.name;
  };

  return (
    <div className="poi-form">
      <ModalInformationCategoryForm
        visible={modalCategory}
        onCancel={() => {
          setModalCategory(false);
        }}
        onCreate={onSubmitCategory}
        category={category}
        setCategory={handleSetCategory}
        isEditing={!!category._id}
      />
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Information Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={information.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Category <span className="is-required">*</span>
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setPlaylistOpen(!playlistOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {information.categoryId === 'all'
                    ? 'Show All'
                    : getCategory(information.categoryId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={categoriesList}
                setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
                handleSetPlaylist={onSelectCategoryHandler}
                tempSelectedCategory={tempSelectedCategory}
                handleNew={handleNew}
                modalOpen={playlistOpen}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.categoryId[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Color</label>
            <ColorPicker
              showText
              value={information.color}
              onChange={(val) => handleChange('color', val.toHexString())}
            />
          </div>
          <div className="form">
            <Checkbox
              checked={information.enableHtmlView}
              onChange={(event) =>
                handleChange('enableHtmlView', event.target.checked)
              }
            >
              Enable Html Formatting for Description
            </Checkbox>
          </div>
          <div className="form">
            <label className="form-label">Description</label>
            {information.enableHtmlView ? (
              <ReactQuill
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={[
                  'header',
                  'font',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                  'video',
                ]}
                theme="snow"
                value={information?.description ?? ''}
                onChange={
                  (val) => handleChange('description', val)
                  // setInformation({
                  //   ...information,
                  //   description: val,
                  // })
                }
              />
            ) : (
              <Input.TextArea
                rows={5}
                placeholder="Information Description"
                className={`${
                  errors && errors.errors && errors.errors.description
                    ? 'has-error'
                    : ''
                }`}
                name="description"
                value={information.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            )}
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Upload Content</label>

            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
              multiple
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
            <div className="upload-chunk-wrapper">
              {information.files.length > 0 &&
                _.chunk(information.files, 3).map((chunk, index) => {
                  return (
                    <div className="upload-chunk" key={index}>
                      {chunk.map((file, index2) => {
                        return (
                          <div className="upload-img-wrapper" key={index2}>
                            <button
                              type="button"
                              className="upload-remove-button"
                              onClick={() => handleRemoveFile(index2)}
                            >
                              <TrashIcon color="#CB3A31" size={18} />
                            </button>
                            <img
                              alt={file.logo}
                              src={
                                file.logo
                                  ? `${imgUrl}/information/${file.logo}`
                                  : URL.createObjectURL(file)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default InformationForm;
