/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker, Input, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { eventsCleanUp } from 'state/actions/event';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
import dayjs from 'dayjs';
import './EventForm.css';
import './EventForm.scss';

const EventForm = ({
  event,
  setEvent,
  success,
  action,
  id,
  history,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  // useEffect(() => {
  //   setErrors(error);
  // }, [error]);

  const { loading, userData } = useSelector(
    (state) => ({
      loading: state.events.loading,
      userData: state.auth.userData,
      categoriesList: state.categories.list,
      floorList: state.floor.list,
      category: state.categories.category,
      floor: state.floor.floor,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line

  useEffect(() => {
    return () => dispatch(eventsCleanUp());
  }, [dispatch]);

  log(event, 'event');

  const onChangeHandler = useChangeHandler(setEvent);

  const onFileChangedHandler = (file) => {
    setEvent((prevState) => ({ ...prevState, file, thumbnail: null }));
  };

  const handleDeletePreviewImage = () => {
    setEvent((prevState) => ({
      ...prevState,
      file: null,
      thumbnail: null,
    }));
  };

  const onSubmitHandler = (value) => {
    log(event);
    value.preventDefault();
    if (
      (!regexInput(event.name) && event.name) ||
      // (!regexUrl(event.url) && event.url) ||
      (!regexInput(event.location) && event.location) ||
      (!regexDescription(event.description) && event.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(event.name) &&
        event.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.location = !regexInput(event.location) &&
        event.location && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.description = !regexDescription(event.description) &&
        event.description && [
          { msg: 'This field cannot contain any special characters' },
        ];

      setErrors(newErrors);
    } else {
      log(event, 'event');
      dispatch(
        action({
          uid,
          ...event,
          siteId,
          id,
        })
      );
    }
  };

  // const canSubmit = event.name && event.description;

  const imagePreviewUrl = event.file
    ? event.file && URL.createObjectURL(event.file)
    : event.thumbnail && `${imgUrl}/${event.thumbnail}`;

  // const goBackMessage = useFormatMessage('EventForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('EventForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('EventForm.pickFile');

  const handleChangeDate = (value) => {
    log(value, 'value daetpickerS');
    if (value) {
      setEvent({
        ...event,
        start: value[0],
        end: value[1],
      });
    } else {
      setEvent({
        ...event,
        start: null,
        end: null,
      });
    }
  };
  // const handleChangeDateTime = (value) => {
  //   if (value) {
  //       setEvent({
  //         ...event,
  //         starttime: value[0],
  //         endtime: value[1],
  //       });

  //   } else {
  //       setEvent({
  //         ...event,
  //         starttime: null,
  //         endtime: null,
  //       });

  //   }
  // };

  return (
    <div className="event-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Event Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="jcr:title"
              value={event['jcr:title']}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Location</label>
            <Input
              placeholder="Event Location"
              className={`${
                errors && errors.errors && errors.errors.location
                  ? 'has-error'
                  : ''
              }`}
              name="location"
              value={event.location}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.location && (
              <span className="error-message">
                {errors.errors.location[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="Event Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="jcr:description"
              value={event['jcr:description']}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Sort Importance</label>
            <Input
              placeholder="Event Sort Importance"
              className={`${
                errors && errors.errors && errors.errors.location
                  ? 'has-error'
                  : ''
              }`}
              name="sortimportance"
              value={event.sortimportance}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.location && (
              <span className="error-message">
                {errors.errors.sortimportance[0].msg}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Image</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>

          <div className="form">
            <label className="form-label">Scheduling:</label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              className="datepicker-adsign"
              format="DD/MM/YYYY HH:mm"
              showTime
              onChange={handleChangeDate}
              value={
                event.start && event.end
                  ? [dayjs(event.start), dayjs(event.end)]
                  : undefined
              }
            />
          </div>
          <div className="form">
            <label className="form-label">Start - End Time</label>
            <div className="input-row">
              <Input
                placeholder="Event Start Time"
                className={`${
                  errors && errors.errors && errors.errors.starttime
                    ? 'has-error'
                    : ''
                }`}
                name="starttime"
                value={event.starttime}
                onChange={onChangeHandler}
              />
              -
              <Input
                placeholder="Event End Time"
                className={`${
                  errors && errors.errors && errors.errors.endtime
                    ? 'has-error'
                    : ''
                }`}
                name="endtime"
                value={event.endtime}
                onChange={onChangeHandler}
              />
            </div>
            {errors &&
              errors.errors &&
              (errors.errors.starttime || errors.errors.endtime) && (
                <span className="error-message">
                  {errors.errors.starttime[0]?.msg ??
                    errors.errors.endtime[0]?.msg}
                </span>
              )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default EventForm;
