import { Avatar, DatePicker, Input, InputNumber, Tooltip, Upload, message, Select } from 'antd';
// import Checkbox from 'antd/lib/checkbox/Checkbox';
// import { useChangeHandler } from 'hooks';
import moment from 'moment';
// import 'moment-timezone';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createMediaCategory,
  fetchMediaCategories,
  modifyMediaCategory,
  setMediaCategory,
} from 'state/actions/mediaCategories';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import _ from 'underscore';
import { setMedia } from 'state/actions/media';
import { ExportIcon, TrashIcon } from 'assets/icon/dynamic';
import CategoryDropdown from 'components/CategoryDropdown';
import ModalMediaCategoryForm from 'components/ModalMediaCategoryForm';
import dayjs from 'dayjs';
import './MediaForm.css';
import './MediaForm.scss';
import { Option } from 'antd/lib/mentions';

function MediaForm({
  // media,
  // setMedia,
  action,
  history,

  id,
  error,
  isEditing,
}) {
  const [isDragged, setIsDragged] = useState(false);
  const { siteId, siteName } = useParams();
  const [errors, setErrors] = useState(error);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [tempSelectedCategory, setTempSelectedCategory] = useState([]);
  const [isModalCategoryShow, setIsModalCategoryShow] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const { loading, userData, categoriesList, media, category, poiList, siteList, role } =
    useSelector(
      (state) => ({
        mediaList: state.media.list,

        poiList: state.pois.list,
        loading: state.media.loading,
        userData: state.auth.userData,
        categoriesList: state.mediaCategories.list,
        siteList: state.sites.list,
        // added by me
        media: state.media.media,
        category: state.mediaCategories.mediaCategory,
        role: state.auth.userData.role,
      }),
      shallowEqual
    );

  const uid = userData.id;

  const dispatch = useDispatch();
  const isCommercial = siteList?.find((site) => site._id === siteId)?.isCommercial;

  const startDate = media.start ? new Date(media.start) : undefined;
  const endDate = media.end ? new Date(media.end) : undefined;
  // const type = media.type;

  log(startDate, endDate, 'start date end date');

  useEffect(() => {
    setErrors(error);
  }, [error]);

  useEffect(() => {
    if (siteId) {
      dispatch(fetchMediaCategories({ siteId }));
    }
  }, [fetchMediaCategories]); // eslint-disable-line

  useEffect(() => {
    setImagePreviewUrl(
      media.file
        ? media.file && URL.createObjectURL(media.file)
        : media.logo && `${imgUrl}/media/${media.logo}`
    );
  }, [media]);

  const onFileChangedHandler = (file) => {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/gif' ||
      file.type === 'video/x-msvideo' ||
      file.type === 'video/quicktime' ||
      file.type === 'video/mp4'
    ) {
      if (file.size / 1000000 <= 300) {
        // setMedia((prevState) => ({ ...prevState, file, logo: null }));
        dispatch(
          setMedia({
            ...media,
            file,
          })
        );
        return false;
      }
      message.error('Maximum file size is 300MB');
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  const handleDeletePreviewImage = () => {
    setMedia((prevState) => ({ ...prevState, file: null, logo: null }));
    setImagePreviewUrl(null);
  };

  const handleChange = (name, value) => {
    dispatch(
      setMedia({
        ...media,
        [name]: value,
      })
    );
  };

  const onSelectCategoryHandler = () => {
    // const categoryId = value.value;
    // const categoryName = value.label;

    dispatch(
      setMedia({
        ...media,
        categoryId: tempSelectedCategory,
        categoryName: categoriesList.find(
          (cat) => cat._id === tempSelectedCategory
        )?.name,
      })
    );
    setPlaylistOpen(false);
  };

  const handleChangeDate = (value) => {

    if (value) {
      dispatch(
        setMedia({
          ...media,
          start: value[0].format(),
          end: value[1].format(),
        })
      );
    } else {
      dispatch(
        setMedia({
          ...media,
          start: null,
          end: null,
        })
      );
    }
  };

  const handleSubmit = () => {
    if (
      (!regexInput(media.name) && media.name) ||
      (!regexDescription(media.description) && media.description) ||
      media.categoryList.length < 1 ||
      (!id && !media.file)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(media.name) &&
        media.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      if (!id && !media.file) {
        newErrors.errors.file = [
          {
            msg: 'Upload a media',
          },
        ];
      }

      if (media.categoryList.length < 1) {
        newErrors.errors.category = [
          {
            msg: 'Must select category',
          },
        ];
      }

      newErrors.errors.description = !regexDescription(media.description) &&
        media.description && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      setErrors(newErrors);
    } else {
      dispatch(
        action(
          {
            uid,
            ...media,
            categoryList: media.categoryList,
            siteId,
            type: media.start || media.end ? 'date' : 'all',
            start: media.start && moment(media.start).format(),
            end: media.end && moment(media.end).format(),
            days: [],
            dates: [],
          },
          () => history.push(`/${siteId}/information/${siteName}`)
        )
      );
    }
  };

  const handleSelectCategory = (catId) => {
    const newCat = [...tempSelectedCategory];
    const findCat = newCat.find((cat) => cat._id === catId);
    if (findCat) {
      const updateCat = newCat.filter((cat) => cat._id !== catId);
      setTempSelectedCategory(updateCat);
    } else {
      setTempSelectedCategory([
        ...tempSelectedCategory,
        {
          _id: catId,
        },
      ]);
    }
  };

  const handleSetCategory = () => {
    dispatch(
      setMedia({
        ...media,
        categoryList: _.pluck(tempSelectedCategory, '_id'),
      })
    );
    setPlaylistOpen(false);
  };

  const getCategory = (categoryId) => {
    const findCategory = categoriesList.find((cat) => cat._id === categoryId);
    return findCategory?.name;
  };

  const handleNew = () => {
    dispatch(
      setMediaCategory({
        name: '',
        description: '',
      })
    );

    setIsModalCategoryShow(true);
  };

  const onCreate = () => {
    const action = category._id ? modifyMediaCategory : createMediaCategory;

    dispatch(
      action(
        { uid, ...category, siteId, id: category._id },
        setIsModalCategoryShow
      )
    );
  };

  const setCategory = (data) => {
    dispatch(setMediaCategory(data));
  };

  return (
    <div className="media-form">
      <ModalMediaCategoryForm
        visible={isModalCategoryShow}
        onCancel={() => {
          setIsModalCategoryShow(false);
        }}
        onCreate={onCreate}
        category={category}
        setCategory={setCategory}
        isEditing={!!category._id}
      />
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Enter media's name"
              name="name"
              type="text"
              value={media.name}
              // onChange={onChangeHandler}
              onChange={(e) => handleChange('name', e.target.value)}
            />

            {/* {isDuplicate && (
              <span className="error-message">Name already exist!</span>
            )} */}

            {errors &&
              errors.errors &&
              errors.errors.name &&
              errors.errors.name.map((name, idx) => (
                <div className="error-message" key={idx}>
                  {name.msg}
                </div>
              ))}
          </div>
          <div className="form">
            <label className="form-label">
              Configuration <span className="is-required">*</span>
            </label>
            {isEditing ? (
              <div
                className="playlist-wrapper"
                onClick={() => setPlaylistOpen(!playlistOpen)}
              >
                <div className="playlist-selection">
                  <span>
                    {media.categoryId === 'all'
                      ? 'Show All'
                      : getCategory(media.categoryId)}
                  </span>
                </div>
                <i
                  className={`feather-chevron-down ${
                    playlistOpen ? 'playlist-open' : 'playlist-closed'
                  }`}
                />

                <CategoryDropdown
                  categories={categoriesList}
                  setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
                  handleSetPlaylist={onSelectCategoryHandler}
                  tempSelectedCategory={tempSelectedCategory}
                  handleNew={handleNew}
                  modalOpen={playlistOpen}
                  title="Configuration"
                />
              </div>
            ) : (
              <div
                className={`playlist-wrapper ${playlistOpen ? 'active' : ''}`}
                onClick={() => setPlaylistOpen(!playlistOpen)}
              >
                <div className="playlist-selection">
                  {media.categoryList && media.categoryList.length > 0 ? (
                    <span className="playlist-value">
                      {_.pluck(
                        categoriesList.filter((cat) =>
                          media.categoryList.includes(cat._id)
                        ),
                        'name'
                      ).join(', ')}
                    </span>
                  ) : (
                    <span className="playlist-placeholder">
                      {/* {selectedCategory === 'all'
                  ? 'All Playlist'
                  : getCategory(selectedCategory)} */}
                      Select Configuration
                    </span>
                  )}
                </div>
                <i
                  className={`feather-chevron-down ${
                    playlistOpen ? 'playlist-open' : 'playlist-closed'
                  }`}
                />

                <div
                  className={`playlist-dropdown ${
                    playlistOpen
                      ? 'playlist-dropdown-open'
                      : 'playlist-dropdown-closed'
                  }`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <ul>
                    {role === 'superadmin' && (
                      <li className="header" onClick={handleNew}>
                        <i className="feather-plus" />
                        Add New Configuration
                      </li>
                    )}
                    {categoriesList.map((cat) => {
                      return (
                        <li key={cat._id}>
                          <div className="playlist-filter">
                            <div className="playlist-filter-radio radio-between">
                              {cat.name}
                              <div
                                className={`checkbox ${
                                  _.pluck(tempSelectedCategory, '_id').includes(
                                    cat._id
                                  )
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => handleSelectCategory(cat._id)}
                              >
                                {_.pluck(tempSelectedCategory, '_id').includes(
                                  cat._id
                                ) && (
                                  <div className="checkbox-checked">
                                    <i className="feather-check" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    <li className="playlist-button" onClick={handleSetCategory}>
                      Set Configuration
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {errors &&
              errors.errors &&
              errors.errors.category &&
              errors.errors.category.map((category, idx) => (
                <div className="error-message" key={idx}>
                  {category.msg}
                </div>
              ))}
          </div>

          <div className="form">
            <label className="form-label">
              Duration <span className="is-required">*</span>
            </label>
            <div className="input-group">
              {/* <TimePicker
                  onChange={onDurationChangeHandler}
                  format={format}
                  value={moment(
                    `${media.duration ? media.duration : '00'}`,
                    format
                  )}
                /> */}
              <InputNumber
                size="large"
                // onChange={onDurationChangeHandler}
                placeholder="Enter duration"
                onChange={(value) => handleChange('duration', value)}
                value={media.duration}
              />
              <div>Seconds</div>
            </div>

            {errors &&
              errors.errors &&
              errors.errors.duration &&
              errors.errors.duration.map((duration, idx) => (
                <div className="error-message" key={idx}>
                  {duration.msg}
                </div>
              ))}
          </div>

          {isCommercial &&
            <div className="form">
              <label className="form-label">Associated POI</label>
              <Select
                mode="multiple"
                optionLabelProp="label"
                filterOption={(input, opt) =>
                  (opt?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                value={media.associatedPois || []}
                onChange={(e) =>
                  dispatch(setMedia({ ...media, associatedPois: e }))
                }
              >
                {poiList.map((poi) => (
                  <Option key={poi._id} value={poi._id} label={poi.name}>
                    <div className="option-tag">
                      <Avatar size="small" src={`${imgUrl}/poi/${poi.logo}`} />{' '}
                      {poi.name}
                    </div>
                  </Option>
                ))}
              </Select>

              {errors && errors.errors && errors.errors.location && (
                <span className="error-message">
                  {errors.errors.location[0].msg}
                </span>
              )}
            </div>
          }

          <div className="form">
            <label className="form-label">Start Date / End Date</label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              className="datepicker-adsign"
              format="DD/MM/YYYY HH:mm"
              showTime
              onChange={handleChangeDate}
              value={
                media.start && media.end
                  ? [dayjs(media.start), dayjs(media.end)]
                  : undefined
              }
            />
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">
              Upload <span className="is-required">*</span>
            </label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <TrashIcon size={18} color="#CB3A31" />
                  </button>
                </Tooltip>
                {(media.file && media.file.type === 'video/mp4') ||
                (media.fileType === 'video/mp4' && !media.file) ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={imagePreviewUrl}
                  >
                    <source src={imagePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
                style={{ width: '100%' }}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors &&
              errors.errors &&
              errors.errors.file &&
              errors.errors.file.map((file, idx) => (
                <div className="error-message" key={idx}>
                  {file.msg}
                </div>
              ))}
          </div>

          <div className="form">
            <label className="form-label">Description </label>
            <Input.TextArea
              placeholder="Description"
              name="description"
              value={media.description}
              // onChange={onChangeHandler}
              onChange={(e) => handleChange('description', e.target.value)}
              rows={4}
            />
            {errors &&
              errors.errors &&
              errors.errors.description &&
              errors.errors.description.map((description, idx) => (
                <div className="error-message" key={idx}>
                  {description.msg}
                </div>
              ))}
          </div>
        </div>
      </form>
      <div className="button-wrapper">
        {loading ? (
          <button type="button" disabled className="button-primary">
            Loading...
          </button>
        ) : (
          <button type="button" className="btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default MediaForm;
