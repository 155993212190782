import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import { doOpenMenu, doOpenParents } from 'state/actions/menu';
import './style.css';

// icon
import SidebarItem from 'elements/sidebar-item';
import SidebarMenu from 'elements/sidebar-menu';
import MainTitle from 'elements/sidebar-main-title';
import { setMediaCategoryFilter } from 'state/actions/media';
import { ReactComponent as OverviewIcon } from '../../assets/icon/global-search.svg';
import { ReactComponent as OverviewActiveIcon } from '../../assets/icon/active/global-search.svg';
import { ReactComponent as OrganizationIcon } from '../../assets/icon/element-equal.svg';
import { ReactComponent as OrganizationActiveIcon } from '../../assets/icon/active/element-equal.svg';
import { ReactComponent as SiteIcon } from '../../assets/icon/3square.svg';
import { ReactComponent as SiteActiveIcon } from '../../assets/icon/active/3square.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icon/user-square.svg';
import { ReactComponent as ProfileActiveIcon } from '../../assets/icon/active/user-square.svg';
import { ReactComponent as AuditIcon } from '../../assets/icon/calendar-edit.svg';
import { ReactComponent as AuditActiveIcon } from '../../assets/icon/active/calendar-edit.svg';
import { ReactComponent as AccessIcon } from '../../assets/icon/key.svg';
import { ReactComponent as AccessActiveIcon } from '../../assets/icon/active/key.svg';
import { ReactComponent as AdminIcon } from '../../assets/icon/candle-2.svg';
import { ReactComponent as AdminActiveIcon } from '../../assets/icon/active/candle-2.svg';
import { ReactComponent as BugReportIcon } from '../../assets/bug-report.svg';
import { logout } from '../../state/actions/auth';

const Sidebar = () => {
  const { organizations, sites, openMenu, openParents, user, role } =
    useSelector(
      (state) => ({
        user: state.auth.userData,
        logoUrl: state.auth.userData.logoUrl,
        role: state.auth.userData.role,
        organizations: state.organizations.list,
        sites: state.sites.list,
        openMenu: state.menu.openMenu,
        openParents: state.menu.openParents,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  const [menuHover, setMenuHover] = useState('');

  const handleGoTo = (menu, parent = '') => {
    dispatch(doOpenMenu(menu, parent));
  };

  const findMenuHover = (menu) => {
    setMenuHover(menu);
  };

  const handleOpenParents = (id) => {
    const newParents = [...openParents];
    const findParents = newParents.find((child) => child._id === id);
    if (findParents) {
      findParents.open = !findParents.open;
      dispatch(doOpenParents(newParents));
    } else {
      dispatch(
        doOpenParents([
          ...openParents,
          {
            _id: id,
            open: true,
          },
        ])
      );
    }
  };

  const findParentsOpen = (id) => {
    const findParents = openParents.find((x) => x._id === id);
    if (findParents) {
      return findParents.open;
    }
    return false;
  };

  const userSiteId =
    user.role !== 'superadmin' &&
    user &&
    user.roleAccess &&
    user.roleAccess.access &&
    user.roleAccess.access.map((access) => access.siteId);
  if (user && !user.role) {
    dispatch(logout());
  }
  let sitesByRole =
    user.role !== 'superadmin'
      ? userSiteId?.map((id) => sites.filter((site) => id === site._id)[0])
      : sites;

  sitesByRole = sitesByRole.filter((site) => site !== undefined);
  const organizationsByRole =
    user.role !== 'superadmin'
      ? _.uniq(
          sitesByRole.map(
            (site) =>
              organizations.filter(
                (organization) => site.organizationId === organization._id
              )[0]
          ),
          (x) => x._id
        )
      : organizations;

  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <img src={require('../../assets/logo-new.png')} alt="Adsign" />
        <ul className="menu-sidebar">
          <MainTitle>Overview</MainTitle>
          <li
            className={`sidebar-menu-item ${
              openMenu === 'overview' ? 'active' : ''
            }`}
            onMouseOver={() => findMenuHover('overview')}
            onMouseLeave={() => findMenuHover('')}
          >
            <Link
              to="/"
              onClick={() => {
                handleGoTo('overview');
              }}
            >
              {menuHover === 'overview' || openMenu === 'overview' ? (
                <OverviewActiveIcon />
              ) : (
                <OverviewIcon />
              )}
              <div>General Overview</div>
            </Link>
          </li>
          <MainTitle>Features</MainTitle>
          <ul>
            {role === 'superadmin' && (
              <li className={`menu-sidebar-subtitle has-children `}>
                <div className="menu-has-children">
                  <div
                    className="menu-parent"
                    onClick={() => handleOpenParents('master')}
                  >
                    <div>Master Data</div>
                    <i
                      className={`feather-chevron-down ${
                        findParentsOpen('master')
                          ? 'icon-chevron-open'
                          : 'icon-chevron-close'
                      }`}
                    />
                  </div>
                  <ul
                    className={`menu-sidebar-children ${
                      findParentsOpen('master')
                        ? 'menu-sidebar-children-open'
                        : 'menu-sidebar-children-close'
                    }`}
                  >
                    <SidebarMenu
                      parentName="organizations"
                      title="Organization"
                      activeIcon={<OrganizationActiveIcon />}
                      icon={<OrganizationIcon />}
                      isDropdown={false}
                      to="/organizations"
                      clickAction={() => {
                        handleGoTo('organizations');
                      }}
                    />
                    <SidebarMenu
                      parentName="sites"
                      title="Site"
                      activeIcon={<SiteActiveIcon />}
                      icon={<SiteIcon />}
                      isDropdown={false}
                      to="/sites"
                      clickAction={() => {
                        handleGoTo('sites');
                      }}
                    />
                    <SidebarMenu
                      parentName="analytics"
                      title="Organization Analytics"
                      activeIcon={<SiteActiveIcon />}
                      icon={<SiteIcon />}
                      isDropdown={false}
                      to="/analytics"
                      clickAction={() => {
                        handleGoTo('analytics');
                      }}
                    />
                  </ul>
                </div>
              </li>
            )}

            <li className="menu-sidebar-subtitle has-children }">
              <div className="menu-has-children">
                <div
                  className="menu-parent"
                  onClick={() => handleOpenParents('sites')}
                >
                  <div>Site Management</div>
                  <i
                    className={`feather-chevron-down ${
                      findParentsOpen('sites')
                        ? 'icon-chevron-open'
                        : 'icon-chevron-close'
                    }`}
                  />
                </div>
                <ul
                  className={`menu-sidebar-children ${
                    findParentsOpen('sites')
                      ? 'menu-sidebar-children-open'
                      : 'menu-sidebar-children-close'
                  }`}
                >
                  {_.sortBy(organizationsByRole, 'name').map((organization) => {
                    return (
                      <SidebarMenu
                        key={organization._id}
                        parentName={organization._id}
                        title={organization.name}
                        activeIcon={<OrganizationActiveIcon />}
                        icon={<OrganizationIcon />}
                      >
                        {_.sortBy(sitesByRole, 'name')
                          .filter(
                            (site) => site.organizationId === organization._id
                          )
                          .map((site) => {
                            return (
                              <SidebarItem
                                key={site._id}
                                title={site.name}
                                className={`${
                                  openMenu === site._id ? 'active' : ''
                                }`}
                                to={`/${site._id}/information/${site.name}`}
                                clickAction={() => {
                                  dispatch(setMediaCategoryFilter('all'));
                                  handleGoTo(site._id, organization._id);
                                }}
                              />
                            );
                          })}
                      </SidebarMenu>
                    );
                  })}
                </ul>
              </div>
            </li>

            <li className="menu-sidebar-subtitle has-children}">
              <div className="menu-has-children">
                <div
                  className="menu-parent"
                  onClick={() => handleOpenParents('configuration')}
                >
                  <div>Configuration</div>
                  <i
                    className={`feather-chevron-down ${
                      findParentsOpen('configuration')
                        ? 'icon-chevron-open'
                        : 'icon-chevron-close'
                    }`}
                  />
                </div>
                <ul
                  className={`menu-sidebar-children ${
                    findParentsOpen('configuration')
                      ? 'menu-sidebar-children-open'
                      : 'menu-sidebar-children-close'
                  }`}
                >
                  <li
                    className={`${openMenu === 'profile' ? 'active' : ''}`}
                    onMouseOver={() => findMenuHover('profile')}
                    onMouseLeave={() => findMenuHover('')}
                  >
                    <Link
                      to="/profile"
                      onClick={() => {
                        handleGoTo('profile');
                      }}
                    >
                      {menuHover === 'profile' || openMenu === 'profile' ? (
                        <ProfileActiveIcon />
                      ) : (
                        <ProfileIcon />
                      )}
                      <div>Profile</div>
                    </Link>
                  </li>
                  <li
                    className={`${openMenu === 'bugs' ? 'active' : ''}`}
                    onMouseOver={() => findMenuHover('bugs')}
                    onMouseLeave={() => findMenuHover('')}
                  >
                    <Link
                      to="/bugs"
                      onClick={() => {
                        handleGoTo('bugs');
                      }}
                    >
                      {menuHover === 'bugs' || openMenu === 'bugs' ? (
                        <BugReportIcon />
                      ) : (
                        <BugReportIcon />
                      )}
                      <div>Bug Report</div>
                    </Link>
                  </li>
                  {user.role === 'superadmin' || user.role === 'admin' ? (
                    <SidebarMenu
                      parentName="audit-logs"
                      title="Audit Logs"
                      activeIcon={<AuditActiveIcon />}
                      icon={<AuditIcon />}
                    >
                      <SidebarItem
                        title="System Logs"
                        className={`${openMenu === 'logs' ? 'active' : ''}`}
                        to="/logs"
                        clickAction={() => handleGoTo('logs', 'audit-logs')}
                      />
                      <SidebarItem
                        title="Authentication Logs"
                        className={`${openMenu === 'authlogs' ? 'active' : ''}`}
                        to="/authlogs"
                        clickAction={() => handleGoTo('authlogs', 'audit-logs')}
                      />
                    </SidebarMenu>
                  ) : null}
                </ul>
              </div>
            </li>

            {user.role === 'superadmin' && (
              <li className={`menu-sidebar-subtitle has-children `}>
                <div className="menu-has-children">
                  <div
                    className="menu-parent"
                    onClick={() => handleOpenParents('admin')}
                  >
                    <div>Admin Panel</div>
                    <i
                      className={`feather-chevron-down ${
                        findParentsOpen('admin')
                          ? 'icon-chevron-open'
                          : 'icon-chevron-close'
                      }`}
                    />
                  </div>
                  <ul
                    className={`menu-sidebar-children ${
                      findParentsOpen('admin')
                        ? 'menu-sidebar-children-open'
                        : 'menu-sidebar-children-close'
                    }`}
                  >
                    {user.role === 'superadmin' && (
                      <SidebarMenu
                        parentName="access-management"
                        title="Access Management"
                        activeIcon={<AccessActiveIcon />}
                        icon={<AccessIcon />}
                      >
                        <SidebarItem
                          title="Roles"
                          className={`${openMenu === 'roles' ? 'active' : ''}`}
                          to="/roles"
                          clickAction={() =>
                            handleGoTo('roles', 'access-management')
                          }
                        />
                        <SidebarItem
                          title="User Management"
                          className={`${openMenu === 'users' ? 'active' : ''}`}
                          to="/users"
                          clickAction={() =>
                            handleGoTo('users', 'access-management')
                          }
                        />
                        <SidebarItem
                          title="Screen Management"
                          className={`${
                            openMenu === 'screen-users' ? 'active' : ''
                          }`}
                          to="/screen-users"
                          clickAction={() =>
                            handleGoTo('screen-users', 'access-management')
                          }
                        />
                        <SidebarItem
                          title="MFA Management"
                          className={`${openMenu === 'logs' ? 'active' : ''}`}
                          to="/mfa-management"
                          clickAction={() =>
                            handleGoTo('mfa', 'access-management')
                          }
                        />
                      </SidebarMenu>
                    )}
                    {user.role === 'superadmin' && (
                      <SidebarMenu
                        title="Admin Panel"
                        parentName="admin-panel"
                        activeIcon={<AdminActiveIcon />}
                        icon={<AdminIcon />}
                      >
                        <SidebarItem
                          title="Roles Authentication Policy"
                          className={`${openMenu === 'panel' ? 'active' : ''}`}
                          to="/panel"
                          clickAction={() => handleGoTo('panel', 'admin-panel')}
                        />
                        <SidebarItem
                          title="User Authentication Policy"
                          className={`${
                            openMenu === 'paneluser' ? 'active' : ''
                          }`}
                          to="/paneluser"
                          clickAction={() =>
                            handleGoTo('paneluser', 'admin-panel')
                          }
                        />
                      </SidebarMenu>
                    )}
                  </ul>
                </div>
              </li>
            )}
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
