import { Form, Input, Modal, Select } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { screensCleanUp } from 'state/actions/screens';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';
import { Option } from 'antd/lib/mentions';

export default function ModalScreenForm({
  screen,
  setScreen,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) {
  const dispatch = useDispatch();
  const { siteId } = useParams();

  const [form] = Form.useForm();

  const { loading, siteList,screenGroupList } = useSelector(
    (state) => ({
      loading: state.sites.loading,
      siteList: state.sites.list,
      screenGroupList: state.screengroup.list,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const isAdsum = siteList?.find((site) => site._id === siteId)?.isAdsum;

  useEffect(() => {
    return () => dispatch(screensCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setScreen);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain a special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain a special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...screen,
        id: screen._id,
        name: values.name,
        adsumScreenId: values.adsumScreenId,
        siteId,
        description: values.description,
      });
      form.resetFields();
    }
  };
  const onChangeScreenGroup = (value) => {
    setScreen((prevState) => ({
      ...prevState,
      groupId: value,
    }));
  };

  return (
    <Modal
      visible={visible}
      centered
      title={isEditing ? 'Edit Screen' : 'Create New Screen'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: screen.name,
          description: screen.description,
          adsumScreenId: screen.adsumScreenId,
          groupId: screen.groupId,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>

        {isAdsum && (
          <Form.Item
            label="Adsum Screen Id"
            name="adsumScreenId"
            rules={[]}
            validateStatus={errors.name ? 'error' : 'success'}
            help={errors.name ? errors.name : null}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="Screen Group"
          name="groupId"
          validateStatus={errors.groupId ? 'error' : 'success'}
          help={errors.groupId ? errors.groupId : null}
        >
          <Select
            placeholder="Select Access"
            value={screen.groupId}
            onChange={onChangeScreenGroup}
            // disabled={userData && userData.role === 'admin'}
          >
            {screenGroupList.map((group) => (
              <Option key={group._id} value={group._id}>
                {group.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModalScreenForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};
