/* eslint-disable jsx-a11y/label-has-associated-control */
import { Tooltip, Upload } from 'antd';
import React, {  useState } from 'react';
// import InputMask from 'react-input-mask';
import { ExportIcon } from 'assets/icon/dynamic';


const ImageUpload = ({
onFileChangedHandler,
handleDeletePreviewImage,
imagePreviewUrl,
fileParams,
fileTypes="image/*,video/mp4",
}) => {


  const [isDragged, setIsDragged] = useState(false);

  return (

            imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(fileParams?.type === 'video/mp4'||imagePreviewUrl.split('.')[imagePreviewUrl.split('.').length-1] === 'mp4')  ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={imagePreviewUrl}
                  >
                    <source src={imagePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept={fileTypes}
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )



  );
};

export default ImageUpload;
