import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';
import dayjs from 'dayjs';

export const EVENTS_FETCH_DATA_INIT = createAction('EVENTS_FETCH_DATA_INIT');
export const EVENTS_FETCH_DATA_SUCCESS = createAction(
  'EVENTS_FETCH_DATA_SUCCESS'
);
export const EVENTS_FETCH_DATA_FAIL = createAction('EVENTS_FETCH_DATA_FAIL');

export const EVENTS_DELETE_EVENT_INIT = createAction(
  'EVENTS_DELETE_EVENT_INIT'
);
export const EVENTS_DELETE_EVENT_SUCCESS = createAction(
  'EVENTS_DELETE_EVENT_SUCCESS'
);
export const EVENTS_DELETE_EVENT_FAIL = createAction(
  'EVENTS_DELETE_EVENT_FAIL'
);

export const EVENTS_CLEAR_DATA = createAction('EVENTS_CLEAR_DATA');

export const EVENTS_CREATE_EVENT_INIT = createAction(
  'EVENTS_CREATE_EVENT_INIT'
);
export const EVENTS_CREATE_EVENT_SUCCESS = createAction(
  'EVENTS_CREATE_EVENT_SUCCESS'
);
export const EVENTS_CREATE_EVENT_FAIL = createAction(
  'EVENTS_CREATE_EVENT_FAIL'
);

export const EVENTS_MODIFY_EVENT_INIT = createAction(
  'EVENTS_MODIFY_EVENT_INIT'
);
export const EVENTS_MODIFY_EVENT_SUCCESS = createAction(
  'EVENTS_MODIFY_EVENT_SUCCESS'
);
export const EVENTS_MODIFY_EVENT_FAIL = createAction(
  'EVENTS_MODIFY_EVENT_FAIL'
);

export const EVENTS_CLEAN_UP = createAction('EVENTS_CLEAN_UP');

export const EVENTS_CLEAR_DATA_LOGOUT = createAction(
  'EVENTS_CLEAR_DATA_LOGOUT'
);
export const EVENTS_SYNC_EVENT_INIT = createAction('EVENTS_SYNC_EVENT_INIT');
export const EVENTS_SYNC_EVENT_SUCCESS = createAction(
  'EVENTS_SYNC_EVENT_SUCCESS'
);
export const EVENTS_SYNC_EVENT_FAIL = createAction('EVENTS_SYNC_EVENT_FAIL');

export const fetchEvents = ({
  siteId,
  type,
  // categoryId = 'all',
  // type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(EVENTS_FETCH_DATA_INIT());

    if (siteId) {
      let events;
      try {
        events = await Axios.post(
          `${url}/event/filter`,
          {
            siteId,
            type,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(EVENTS_FETCH_DATA_FAIL({ error }));
      }
      events = events.data.data;
      return dispatch(
        EVENTS_FETCH_DATA_SUCCESS({
          list: events,
          event: getState().events.event,
        })
      );
    }
  };
};

export const syncEvent = () => {
  return async (dispatch) => {
    dispatch(EVENTS_SYNC_EVENT_INIT());
    Axios.get(`${url}/event/fetch`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      // responseType: 'blob',
      // params: {
      //   siteId,
      // },
    })
      .then(() => {
        return dispatch(EVENTS_SYNC_EVENT_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(EVENTS_SYNC_EVENT_FAIL({ error }));
      });
  };
};

export const createEvent = (data) => {
  return async (dispatch) => {
    dispatch(EVENTS_CREATE_EVENT_INIT());

    // let createEvent;

    const event = new FormData();
    event.set('path', 'event');
    event.set('jcr:title', data['jcr:title']);
    if (data['jcr:description'])
      event.set('jcr:description', data['jcr:description']);

    event.set('siteId', data.siteId);
    event.set(
      'sortimportance',
      data.sortimportance ? data.sortimportance : '999'
    );
    if (data.location) event.set('location', data.location);
    event.append('file', data.file);
    if (data.start && data.end) {
      event.set('start', dayjs(data.start).format());
      event.set('end', dayjs(data.end).format());
    }
    if (data.starttime && data.endtime) {
      event.set('starttime', data.starttime);
      event.set('endtime', data.endtime);
    }

    try {
      await Axios.post(`${url}/event/create`, event, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        EVENTS_CREATE_EVENT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(event));
    toastr.success('', 'Event created successfully');
    return dispatch(EVENTS_CREATE_EVENT_SUCCESS({ event: eventJson }));
  };
};

export const modifyEvent = (data) => {
  return async (dispatch) => {
    dispatch(EVENTS_MODIFY_EVENT_INIT());

    console.log(data, 'data');
    const event = new FormData();
    event.set('path', 'event');
    event.set('id', data.id);
    event.set('jcr:title', data['jcr:title']);
    event.set(
      'sortimportance',
      data.sortimportance ? data.sortimportance : '999'
    );
    if (data['jcr:description'])
      event.set('jcr:description', data['jcr:description']);
    if (data.starttime) event.set('starttime', data.starttime);
    if (data.endtime) event.set('endtime', data.endtime);
    event.set('siteId', data.siteId);
    if (data.location) event.set('location', data.location);
    if (data.thumbnail) {
      event.set('thumbnail', data.thumbnail);
    }
    event.append('file', data.file);

    console.log(data.start, data.end);
    if (data.start && data.end) {
      event.set('start', dayjs(data.start).format());
      event.set('end', dayjs(data.end).format());
    }
    if (data.starttime && data.endtime) {
      event.set('starttime', data.starttime);
      event.set('endtime', data.endtime);
    }

    try {
      await Axios.put(`${url}/event/edit`, event, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        EVENTS_MODIFY_EVENT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(event));
    toastr.success('', 'Event updated successfully');
    return dispatch(EVENTS_MODIFY_EVENT_SUCCESS({ eventJson, id: data.id }));
  };
};

export const deleteEvent = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(EVENTS_DELETE_EVENT_INIT());
    try {
      await Axios.post(
        `${url}/event/delete`,
        {
          eventId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        EVENTS_DELETE_EVENT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(EVENTS_DELETE_EVENT_SUCCESS({ id }));
  };
};

export const eventsCleanUp = () => (dispatch) => dispatch(EVENTS_CLEAN_UP());
