import ModalScreenForm from 'components/ModalScreenForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { log } from 'utils';
import {
  createScreen,
  deleteScreen,
  fetchScreens,
  modifyScreen,
  setActiveScreen,
} from 'state/actions/screens';
import { clearUsersData } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import { ForwardIcon } from 'assets/icon';
import ModalDeleteConfirmation from 'components/ModalDeleteConfirmation';
import Cards from 'design-components/Cards';
import _ from 'underscore';
import { filterAccess } from 'components/Report/utils';
import './Screens.scss';
import CategoryDropdown from '../../components/CategoryDropdown';
import {
  createScreenGroup,
  deleteScreenGroup, fetchScreenGroups,
  modifyScreenGroup,
  setScreenGroup
} from '../../state/actions/screenGroup';
import ModalMediaCategoryForm from '../../components/ModalMediaCategoryForm';
import ModalDelete from '../../components/ModalDelete';

export default function Screens({ history }) {
  const { siteId, siteName } = useParams();

  const {
    sites,
    error,
    loading,
    deleted,
    updated,
    screensList,
    screenGroupList,
    userData,
    loadingScreenGroup,
    role,
    screenGroup
  } = useSelector(
    (state) => ({
      sites: state.sites.list,
      error: state.screens.error,
      loading: state.screens.loading,
      deleted: state.screens.deleted,
      updated: state.screens.updated,
      screensList: state.screens.list,
      screenGroupList: state.screengroup.list,
      loadingScreenGroup: state.screengroup.loading,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      screenGroup: state.screengroup.screenGroup,
    }),
    shallowEqual
  );

  const defaultScreen = {
    name: '',
    description: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: userData.email ? userData.email : '',
  };
  const uid = userData.id;

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [screen, setScreen] = useState(defaultScreen);
  // const [siteName, setSiteName] = useState('');
  // const [organizationId, setOrganizationId] = useState('');
  const [searchError, setSearchError] = useState(null);

  const [deleteGroup, setDeleteGroup] = useState();
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [tempSelectedGroup, setTempSelectedGroup] = useState(selectedGroup);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScreenGroups({ siteId }));
    dispatch(fetchScreens({ siteId }));
    return () => dispatch(clearUsersData());
  }, [dispatch, role, siteId]); // eslint-disable-line

  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'screen', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'screen', action: 'update' })
    );
  }, [siteId, userData]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchScreens({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchScreens({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line

  const downloadJSONConfig = (selectedScreen) => {
    const site = sites.filter((site) => site._id === siteId);
    const organizationId = site && site[0] ? site.organizationId : '';
    const json = {
      organizationId,
      siteId,
      screenId: selectedScreen._id,
      email: '',
      password: '',
      url: 'https://premise-api.adactive.asia',
      mode: 'offline',
    };
    const content = JSON.stringify(json);
    const filename = `${siteName} ${selectedScreen.name}.json`;
    const contentType = 'text/json';

    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  };

  const getCategory = () => {
    const findCategory = screenGroupList.find(
      (cat) => cat._id === selectedGroup
    );
    return findCategory?.name;
  };

  const handleEditCategory = (id) => {
    const findCategory = screenGroupList.find((cat) => cat._id === id);
    console.log(id, 'id');
    console.log(findCategory, 'findCategory');
    // setIsEditing(true);
    dispatch(setScreenGroup(findCategory));
    setIsModalShow(true);
  };

  const handleDeleteCategory = async (item) => {
    setDeleteGroup(item);
    setIsDeleteModal(true);
  };

  const handleSetPlaylist = () => {
    setSelectedGroup(tempSelectedGroup);
    setDropdownOpen(false);
  };

  const handleDeleteScreenGroup = () => {
    dispatch(
      deleteScreenGroup({ id: deleteGroup._id, siteId }, () =>
        setIsDeleteModal(false)
      )
    );
    setSelectedGroup('all');
  };

  const handleCancelDeleteCategory = () => {
    setDeleteGroup(null);
    setIsDeleteModal(false);
  };

  const onCreateScreenGroup = () => {
    const action = screenGroup._id ? modifyScreenGroup : createScreenGroup;

    dispatch(
      action(
        { uid, ...screenGroup, siteId, id: screenGroup._id },
        setIsModalShow
      )
    );
  };
  const handleAddCategory = () => {
    dispatch(
      setScreenGroup({
        name: '',
        description: '',
      })
    );

    setIsModalShow(true);
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyScreen : createScreen;
    log(values, '<<<< values screen oncreate');
    dispatch(action(values));
  };

  const onNewScreenHandler = () => {
    log('handle new screen');
    setScreen(defaultScreen);
    setIsEditing(false);
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (screenId) => {
    setDeleteModal((prevState) => ({
      screenId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ screenId: null, isOpen: false });
  };

  const onDeleteScreenHandler = () => {
    dispatch(deleteScreen(deleteModal.screenId, siteId));
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const handleGoTo = (screen) => {
    dispatch(
      setActiveScreen(screen, () =>
        history.push(`/${siteId}/screen/${siteName}/${screen._id}`)
      )
    );
  };

  const setScreenGroupData = (data) => {
    dispatch(setScreenGroup(data));
  };
  const data = search
    ? screensList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : screensList;

  const permDeleteMessage = useFormatMessage('Screens.permDelete');

  return (
    <section className="screens">
      {deleteModal.isOpen && (
        <ModalDeleteConfirmation
          title="Delete Screen"
          onCloseHandler={onCloseModalHandler}
          onDeleteHandler={onDeleteScreenHandler}
          description={permDeleteMessage}
          visible={deleteModal.isOpen}
        />
      )}
      <div className="screens__nav">
        <div className="screens__search-wrapper">
          <div className="media-categories__search">
            <i className="feather-search" />
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>

        {filterAccess({
          userData,
          siteId,
          param: 'screen',
          action: 'create',
        }) && (
          <div className="screens__buton-add">
            <button className="button-primary" onClick={onNewScreenHandler}>
              <i className="feather-plus" /> Add Screen
            </button>
          </div>
        )}
      </div>
      <ModalMediaCategoryForm
        visible={isModalShow}
        onCancel={() => {
          setIsModalShow(false);
        }}
        onCreate={onCreateScreenGroup}
        category={screenGroup}
        setCategory={setScreenGroupData}
        isEditing={!!screenGroup._id}
      />
      <ModalDelete
        isVisible={isDeleteModal}
        onOk={handleDeleteScreenGroup}
        onCancel={handleCancelDeleteCategory}
        title="Delete Playlist"
        subtitle={deleteGroup ? deleteGroup.name : ''}
        loading={loadingScreenGroup}
      />
      {visible && (
        <ModalScreenForm
          visible
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          screen={screen}
          setScreen={setScreen}
          isEditing={isEditing}
        />
      )}
      <div className="media-header">
        <div className="media-header-left">
          <div
            className="playlist-wrapper"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <div className="playlist-selection">
              <ForwardIcon />
              <span>
                {selectedGroup === 'all'
                  ? 'All Screen Groups'
                  : getCategory(selectedGroup)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                dropdownOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={screenGroupList}
              setTempSelectedCategory={(id) => setTempSelectedGroup(id)}
              handleEditCategory={
                role === 'superadmin'
                  ? (cat) => handleEditCategory(cat._id)
                  : null
              }
              handleDeleteCategory={
                role === 'superadmin'
                  ? (cat) => handleDeleteCategory(cat)
                  : null
              }
              modalOpen={dropdownOpen}
              handleSetPlaylist={handleSetPlaylist}
              title="Screen Group"
              tempSelectedCategory={tempSelectedGroup}
              handleNew={handleAddCategory}
              filter
            />
          </div>
        </div>
      </div>
      <div className="screens__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <div className="media-content-wrapper">
            <div className="media-content">
              <div className="media-list-content">
                {_.chunk(data, 3).map((chunk, index) => {
                  return (
                    <div className="media-list-chunk" key={index}>
                      {chunk.map((screen) => (
                        <div
                          key={screen._id}
                          data-id={screen._id}
                          className="media-list"
                        >
                          <Cards
                            data={screen}
                            type="screen"
                            isShowMedia
                            editAction={
                              editAccess
                                ? () => {
                                  setScreen(screen);
                                  setVisible(true);
                                  setIsEditing(true);
                                }
                                : null
                            }
                            cardClickAction={
                              sites.find((site) => site._id === siteId)
                                .isDirectory
                                ? () => handleGoTo(screen)
                                : null
                            }
                            deleteAction={
                              deleteAccess
                                ? () => onRemoveButtonClickHandler(screen._id)
                                : null
                            }
                            downloadAction={() => downloadJSONConfig(screen)}
                            isDownload
                            isClickable
                            onClick={() => handleGoTo(screen)}
                          />
                        </div>
                      ))}
                      {chunk.length < 2 && <div className="media-list-empty" />}
                      {chunk.length < 3 && <div className="media-list-empty" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          // <ScreenCard
          //   data={data}
          //   onRemoveButtonClickHandler={(id) => onRemoveButtonClickHandler(id)}
          //   onEditButtonClickHandler={(record) => {
          //     setScreen(record);
          //     setVisible(true);
          //     setIsEditing(true);
          //   }}
          //   handleGoTo={handleGoTo}
          // />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
